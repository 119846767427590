w<template>
  <div class="projects">
    <div class="row">
      <div class="single">
        ...we love Linux, we love open source, so everything we do we do with Linux, either it spins on this OS, or relates to it... 
      </div>
    </div>
    <div class="row">
      <div class="single">
        <p class="p-main">
          Back in 2019, when <span class="company">Strangebit</span> was just founded,
          we had a project which can be described as follows. A customer had a DVB-C
          source (simply speaking it was a digital video signal arriving over coaxial 
          cable). The task was to receive the signal, process it 
          and then make it available over TCP/IP network. More specifically, there was
          a requirement to make video playback available in regular web browser. 
        </p>
        <p>
          Thus we sat down, read the DVB-C specifications, prepared the plan and 
          started to code. The biggest challenge was to configure the DVB-C 
          signal receiver (the device which was demodulating analog electromagnetic
          signal into into digital packets) in a correct way. We studied tons of
          available publicly source codes in order to understand the way to 
          configure the device using <i>ioctl</i> calls. Once it was understood
          how to configure the device, we were able to set such parameters a 
          central frequency, modulation, and error correction. 
        </p>
        <p>
          But the challenge was not over long after this. We still needed to understand
          how to slice the MPEG packets correctly. DVB-C MPEG packets are just 188 bytes long, but in order 
          for the playback to work when delivered over HTTP, the chucks need to be sliced
          correctly. For example, we learned that the video playback would not be possible
          unless the chunks (aggregated DVB-C MPEG packets into single file) start with 
          the so called <i>I-frames</i>. These are special 
          frames in MPEG which play a role of a reference frames which in turn allow to decode
          consequent MPEG frames. 
        </p>
        <p>
          Once the details became clear to us we started to think of the overall architecture 
          of the system. Thus, we split the system into separate logical blocks. The first
          component was responsible for tuning the DVB-C receiver, capturing the 
          MPEG packets, combining them into chunks and encrypting them using AES
          encryption algorithm. The second part, web server, was responsible for 
          serving the chunks over HLS protocol (read HTTP). The web server was 
          also responsible for such functions as authentication of the users,
          fetching the TV guide, and other auxiliary functions. The last part
          was to code the solution and deploy it on a server in a local network. 
          And voilà the result can be seen in the figure below.
        </p>
        <div style="display: flex; justify-content: center;">
          <img src="@/assets/iptv-web-ui.png" alg="IPTV service demo"/>
        </div>
        <p>
          The initial prototype (read source code) of the system that we have discussed in the 
          above paragraphs can be found in our <b>GitHub</b> 
          <a href="https://github.com/dmitriykuptsov/iptv">page</a>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="single">
        <p class="p-main">
          Security is a must in modern days. We are not really experts, but 
          trying hard to follow common sense when building our projects. 
          Security can and should be ensured 
          at all levels. Here is the list of some areas where security 
          should be well thought. First, you need to trust the compiler. Then you need to
          trust the sources from which the OS is assembled. Then you need to trust 
          in mathematics and algorithms (of course, quantum computers endanger the
          security, but this is something we will not see in the next few years)
          upon which most of cryptography relies. Then, you need to trust the
          networks protocols. And of course, one needs to follow the common 
          sense when performing <b>sudo apt-get install</b>. Performing regular
          updates and audits of your system can keep it clean and sanitized. 
        </p>
        <p>
          Enough for excursus, the next project that we have delivered was the related to a secure network 
          infrastructure that is capable of bypassing sophisticated packet inspection
          hardware. The task was simple. We had a number of network devices behind a 
          legacy router. It was required without reconfiguration of the network make  
          all devices become a part of single VPN. So we came up with an idea of building
          a piece of hardware, adding to it some code that will tunnel the traffic over TLS
          protocol, building into it authentication layer and deploying it on the server. The prototype of our effort 
          can be found <a href="https://github.com/dmitriykuptsov/soho-vpn-over-tls">here</a>
        </p>
      </div>
    </div>
    <div class="row">
      <div class="single">
        <p class="p-main">
          Imagine a network comprising 1500 devices. Pretty large isn't it. But what if 
          network engineers need to access those devices to perform checks, reconfiguration
          and scheduled maintenance? How does one manage passwords in a plausible way? 
          TACACS+ is protocol designed by Cisco networks
          solves the problem. All good but it is a Linux daemon running on central server
          that does not have a graphical user interface.
        </p>
        <p>
          We are engineers, right? So we decided to create a web interface so that 
          user management could be done in no hassle. The result, may be not the most
          beautiful one, but it is functional. Take a look. And as usual the source 
          of our effort can be found <a href="https://github.com/dmitriykuptsov/tacacsgui">here</a>
        </p>
        <!-- div style="display: flex; justify-content: center;">
          <img src="@/assets/tacacs.jpg"/>
        </div -->
      </div>
    </div>

    <div class="row">
      <div class="single">
        <p class="p-main">
          One particular interesting project was our recent product that we have
          made for a governmental organization. The task was to design a web portal,
          but one of the requirements was to make access control based on certificates.
          More over the private key has to be stored in a tamper proof device. The device
          should have a possibility to generate internally RSA key pair, store the private
          part, and perform signatures operations on demand. To construct signatures and pass them
          through TLS to a web server we have created a custom driver. Since the project 
          is ongoing we cannot expose much details. But to give a food for a thought 
          we use Tokey®JCOP3 from CrypToken as a proven standard. The result looks like as 
          shown below. 
        </p>
        <div style="display: flex; justify-content: center;">
          <img src="@/assets/rsa_portal.png" alt="Integration with RSA dongle"/>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="single">
        <p class="p-main">
          Virtual Private LAN Services (or VPLS) are quite common nowdays. 
          Companies build VPLS to provide Layer-2 services for branch offices:
          VPLS are typically built as overlays on top of Layer-3 (IP).
          For example, when frame arrives at VPLS provider equipment (PE) it is 
          encapsulated into IP packet and is sent out to all other VPLS network 
          elements comprising emulated LAN. Security of such overlays is important for 
          obvious reasons: customers do not want their corporate traffic to be sniffed and 
          analyzed.
        </p>
        <p>
          At the moment we are working on a solution for securing VPLS with Host Identity 
          Protocol (HIP). The details about HIP can be obtained, for example, from 
          <a href="https://datatracker.ietf.org/doc/html/rfc7401">here</a>.
          We are going to build a <a href="https://github.com/strangebit-io/hip-vpls">proof 
          of a concept</a> implementation using Linux and Python as usual. 
        </p>
      </div>
    </div>


    <div class="row">
      <div class="single">
        <p class="p-main">
          We mentioned earlier that it is wise to have your custom OS ready: this way
          you can control all bits and pieces of your system. For that matter, in the future,
          we intend to build a custom Linux image and test it on the DigitalOcean servers.
          This will increase overall security of the projects we create. Wouldn't it be nice 
          to have a script that builds an image after careful selection of dependencies? 
          Stay tuned for more info...
        </p>
      </div>
    </div>

    <!-- div class="row">
      <div class="single">
        <p class="p-main">
          Performance of wireless networks is important since edge of the networks is becoming
          increasingly wireless. To achieve the best performance, however, we can tweak certain parameters, such as
          backoff factor or even backoff state machine. In our previous work on (<a href="https://scholar.google.fi/citations?view_op=view_citation&hl=en&user=9vIK0i8AAAAJ&citation_for_view=9vIK0i8AAAAJ:roLk4NBRz8UC">modified IEEE 802.11g</a>) we devised the analytical
          model. However, back then, we have used rather incorrect paramters. So here we 
          present a model and use numerical methods (basically, we use bisection method) to find correct values for the backoff factors
          for such protocols. Full report is available <a href="model_wifi.docx">here</a>.
        </p>
      </div>
    </div -->
  </div>
</template>

<style scoped>

@media (max-width:700px) {
  .projects {
    width: 100%;
  }
  img {
    border-radius: 8px;
    max-width: 100%;
    height: auto;
  }
}

@media (min-width:700px) {
  .projects {
    width: 80%;
    margin-left: 10%;
  }
  img {
    border-radius: 8px;
    max-width: 60%;
    height: auto;
  }
}

/*
.projects {
    width: 80%;
    margin-left: calc(10%);
}
*/

.company {
    font-weight: bold;
}

p {
    font-family: Times New Roman;
    font-size: 14pt;
    text-align: justify;
}

.single {
    margin-right: 5%;
    margin-left: 5%;
}

.column {
  float: left;
  width: 50%;
}

.row {
  width: 100%;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.p-main {
    font-family: Times New Roman;
    font-size: 14pt;
    /*margin-left: 10pt;
    margin-right: 10pt;*/
    margin-top: 1pt;
}

.p-main:first-letter {
    text-transform:capitalize;
    color: black;
    font-size: 400%;
    font-weight: bold;
    display: block;
    float: left;
    margin-right: 5pt;
}
</style>
